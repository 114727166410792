import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import { API } from "../utils/apk";

// Configure custom marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
});

// Component to programmatically move the map
const RecenterMap = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, map.getZoom());
  }, [center, map]);
  return null;
};

const DeliveryBoyMap = () => {
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [selectedBoy, setSelectedBoy] = useState(null);

  // Fetch delivery boy data
  useEffect(() => {
    const fetchDeliveryBoys = () => {
      axios
        .get(`${API}/get/all/delivery/boy`)
        .then((response) => {
          console.log("Fetched delivery boys:", response.data); // Log fetched data
          setDeliveryBoys(response.data);
        })
        .catch((error) =>
          console.error("Error fetching delivery boys:", error)
        );
    };

    // Fetch data initially
    fetchDeliveryBoys();

    // Poll every 10 seconds to get updated locations
    const intervalId = setInterval(fetchDeliveryBoys, 10000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex flex-row h-screen mt-4 bg-gray-100">
      {/* Sidebar for listing delivery boys */}
      <div className="w-1/6 p-4 bg-white shadow-lg overflow-auto">
        <h2 className="text-xl font-bold mb-4">Delivery Boys</h2>
        <ul>
          {deliveryBoys.map((boy) => (
            <li
              key={boy._id}
              className={`p-2 cursor-pointer rounded ${
                selectedBoy && selectedBoy._id === boy._id
                  ? "bg-blue-500 text-white"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => setSelectedBoy(boy)}
            >
              {boy.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Map container */}
      <div className="flex-grow">
        <MapContainer
          center={[22.5726, 88.3639]}
          zoom={10}
          className="h-full w-full"
        >
          {/* Tile layer from OpenStreetMap */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {/* Recenter map on selected delivery boy */}
          {selectedBoy && (
            <RecenterMap
              center={[selectedBoy.latitude, selectedBoy.longitude]}
            />
          )}

          {/* Plot delivery boys on the map */}
          {deliveryBoys.map((boy) => (
            <Marker key={boy._id} position={[boy.latitude, boy.longitude]}>
              <Popup>
                <div className="p-2">
                  <h3 className="text-lg font-semibold">{boy.name}</h3>
                  <p>Mobile: {boy.mobileNo}</p>
                  <p>Address: {boy.address}</p>
                  <p>Status: {boy.status}</p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default DeliveryBoyMap;
